<template>
  <page-content>
    <page-title heading="จัดการวีดีโอสอนการใช้งานระบบ"> </page-title>

    <b-row>
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">จัดการวีดีโอสอนการใช้งานระบบ</h5>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="showModalTutorialLesson = true"
              >เพิ่มวีดีโอสอนการใช้งานระบบ</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <lesson-table
      ref="tutorialLessonTable"
      class="my-4"
      title="วีดีโอสอนการใช้งานระบบ"
      :fields="tutorialLessonFields"
      :params="tutorialLessonTableParams"
      @edit="onEditTutorialLesson"
      @delete="onDeleteTutorialLesson"
    />

    <create-or-update-lesson
      :edit-data.sync="tutorialLessonEditData"
      title="วีดีโอสอนการใช้งานระบบ"
      type="tutorial"
      :col-lesson-title="8"
      :hidden-input="{ lessonNo: true }"
      v-model="showModalTutorialLesson"
      @create:success="onCreateTutorialLessonSuccess"
    ></create-or-update-lesson>

    <delete-lesson
      title="วีดีโอสอนการใช้งานระบบ"
      v-model="showModalDeleteTutorialLesson"
      @delete:success="onDeleteTutorialLessonSuccess"
      :delete-data.sync="tutorialLessonDeleteData"
    ></delete-lesson>

    <!-- <b-row>
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">จัดการวีดีโอ</h5>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="showModalVideo = true"
              >เพิ่มวีดีโอ</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <video-table
      ref="videoTable"
      class="mt-4"
      :fields="videoFields"
      @edit="onEditVideo"
      @on-hide="onHideVideo"
      @delete="onDeleteVideo"
    />

    <create-or-update-video
      v-model="showModalVideo"
      :edit-data.sync="videoEditData"
      @create:success="onCreateVideoSuccess"
    ></create-or-update-video>

    <delete-video
      v-model="showModalDeleteVideo"
      @delete:success="onDeleteVideoSuccess"
      :delete-data.sync="videoDeleteData"
    ></delete-video>

    <b-row>
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">จัดการข่าวประชาสัมพันธ์</h5>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="showModalNews = true"
              >เพิ่มข่าว</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <news-table
      ref="newsTable"
      class="mt-4"
      :fields="newsFields"
      :params="newsTableParams"
      @edit="onEditNews"
      @delete="onDeleteNews"
    />

    <create-or-update-news
      :edit-data.sync="newsEditData"
      v-model="showModalNews"
      @create:success="onCreateNewsSuccess"
    ></create-or-update-news>

    <delete-news
      v-model="showModalDeleteNews"
      @delete:success="onDeleteNewsSuccess"
      :delete-data.sync="newsDeleteData"
    ></delete-news> -->
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateLesson from "../../components/modal/CreateOrUpdateLesson";
// import CreateOrUpdateNews from "../../components/modal/CreateOrUpdateNews";
// import CreateOrUpdateVideo from "../../components/modal/CreateOrUpdateVideo";
import LessonTable from "../../components/table/Lesson";
// import NewsTable from "../../components/table/News";
// import VideoTable from "../../components/table/Video";
import DeleteLesson from "../../components/modal/DeleteLesson";
// import DeleteNews from "../../components/modal/DeleteNews";
// import DeleteVideo from "../../components/modal/DeleteVideo";
import { Auth } from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateLesson,
    LessonTable,
    DeleteLesson,
  },

  data() {
    return {
      tutorialLessonEditData: {},
      tutorialLessonDeleteData: {},
      showModalTutorialLesson: false,
      showModalDeleteTutorialLesson: false,
      tutorialLessonFields: [
        // {
        //   key: "lesson_no",
        //   label: "บทเรียนที่",
        //   sortable: true,
        //   class: "text-right",
        // },
        {
          key: "lesson_order",
          label: "ลำดับที่",
          sortable: true,
          class: "text-right",
        },
        {
          key: "lesson_title",
          label: "ชื่อวีดีโอ",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "แก้ไขล่าสุด",
          sortable: true,
        },
      ],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    tutorialLessonTableParams() {
      return {
        lesson_type: "tutorial",
      };
    },

    // bannerTableParams() {
    //   const { bannerFormfilter } = this;

    //   return {
    //     ...bannerFormfilter,
    //   };
    // },

    // newsTableParams() {
    //   const { newsFormfilter } = this;

    //   return {
    //     ...newsFormfilter,
    //   };
    // },
  },

  methods: {
    // onKeywordSearched() {
    //   this.$set(this.formfilter, "keyword", this.keyword);
    // },

    // clearKeyword() {
    //   this.keyword = "";
    //   this.$delete(this.formfilter, "keyword");
    // },

    onEditTutorialLesson(item) {
      this.tutorialLessonEditData = { ...item };
      this.showModalTutorialLesson = true;
    },

    // onEditNews(item) {
    //   this.newsEditData = { ...item };
    //   this.showModalNews = true;
    // },

    // onEditVideo(item) {
    //   this.videoEditData = { ...item };
    //   this.showModalVideo = true;
    // },

    onCreateTutorialLessonSuccess() {
      this.$refs.tutorialLessonTable.refresh();
    },

    // onCreateNewsSuccess() {
    //   this.$refs.newsTable.refresh();
    // },

    // onCreateVideoSuccess() {
    //   this.$refs.videoTable.refresh();
    // },

    onDeleteTutorialLesson(item) {
      this.tutorialLessonDeleteData = { ...item };
      this.showModalDeleteTutorialLesson = true;
    },

    // onDeleteNews(item) {
    //   this.newsDeleteData = { ...item };
    //   this.showModalDeleteNews = true;
    // },

    // onDeleteVideo(item) {
    //   this.videoDeleteData = { ...item };
    //   this.showModalDeleteVideo = true;
    // },

    onDeleteTutorialLessonSuccess() {
      this.$refs.tutorialLessonTable.refresh();
    },

    // onDeleteNewsSuccess() {
    //   this.$refs.newsTable.refresh();
    // },

    // onDeleteVideoSuccess() {
    //   this.$refs.videoTable.refresh();
    // },

    // onHideVideo() {
    //   this.videoEditData = null;
    // },
  },
};
</script>
